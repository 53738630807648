import { graphql } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const ReactPaginate = loadable(() => import("react-paginate"));
const Contact = loadable(() => import("../components/contact"));
const Thumbnail = loadable(() => import("../components/thumbnail"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export const query = graphql`
query {
    allContentfulBlog (sort: {fields: createdAt, order: DESC}){
        edges {
          node {
            category
            title
            slug
            meta
            thumbnail {
              fixed(quality: 100, height: 200, width: 300) {
                src
              }
            }
            createdAt(formatString: "MMMM DD, YYYY")
          }
        }
      }
}`

export class allblogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            pageCount: 0,
            itemsPerPage: 9,
            startOffset: 0,
            blogsArray: [],
            category: null,
            search: '',
            isSelected: null,
        };
    }

    componentDidMount() {
        let { category } = this.state;
        category = localStorage.getItem('category');
        this.setState({ category });
        this.setCategory(category);
    };

    handleChange = (search) => {
        this.searchBlog(search)
        this.setState({ search })
    };

    searchBlog = async (search) => {
        let { data } = this.props;
        let { itemsPerPage, startOffset, blogs } = this.state;
        let selected = 0;
        blogs = [];
        startOffset = 0;
        this.setState({ blogs, startOffset, pageCount: 0, blogsArray: [], category: 'All' },
            async () => {
                if (data['allContentfulBlog'] !== undefined) {
                    let array = await data['allContentfulBlog']['edges'];
                    if (search == '') array.forEach(item => blogs.push(item['node']))
                    else await array.filter(item => (item['node']['title'].toLowerCase()).includes(search.toLowerCase())).forEach(item => blogs.push(item['node']))
                    blogs.sort(compare);
                    this.setState({ blogs },
                        () => {
                            this.handlePageClick({ selected });
                            this.setState({ pageCount: Math.ceil(blogs.length / itemsPerPage) });
                            const endOffset = startOffset + itemsPerPage;
                            this.setState({ blogsArray: blogs.slice(startOffset, endOffset) });
                        });
                }
            })
    };

    setCategory = async (category) => {
        let { data } = this.props;

        let { itemsPerPage, startOffset, blogs } = this.state;
        let selected = 0;
        blogs = [];
        startOffset = 0;
        this.setState({ blogs, startOffset, pageCount: 0, blogsArray: [] },
            async () => {
                if (data['allContentfulBlog'] !== undefined) {
                    let array = await data['allContentfulBlog']['edges'];
                    if (category == null || category == "null" || category == 'All') array.forEach(item => blogs.push(item['node']))
                    else if (category == 'NFT') array.filter(item => item['node']['category'].includes(category)).forEach(item => blogs.push(item['node']))
                    else if (category == 'Metaverse') array.filter(item => item['node']['category'].includes(category)).forEach(item => blogs.push(item['node']))
                    else if (category == 'GameFi') array.filter(item => item['node']['category'].includes(category)).forEach(item => blogs.push(item['node']))
                    else if (category == 'DeFi') array.filter(item => item['node']['category'].includes(category)).forEach(item => blogs.push(item['node']))
                    else if (category == 'Blockchain') array.filter(item => item['node']['category'].includes(category)).forEach(item => blogs.push(item['node']))
                    blogs.sort(compare);
                    this.setState({ blogs },
                        () => {
                            this.handlePageClick({ selected });
                            this.setState({ pageCount: Math.ceil(blogs.length / itemsPerPage) });
                            const endOffset = startOffset + itemsPerPage;
                            this.setState({ blogsArray: blogs.slice(startOffset, endOffset), category });
                            localStorage.setItem('category', category);
                        });
                }
            })
    };

    componentDidUpdate(prevProps, prevState) {
        let { data } = this.props;
        let { itemsPerPage, startOffset, blogs, category } = this.state;
        if (prevProps.data !== data) this.setCategory(category);
        if (blogs.length > 0) {
            if (prevState.startOffset !== startOffset) {
                const endOffset = startOffset + itemsPerPage;
                this.setState({ blogsArray: blogs.slice(startOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(blogs.length / itemsPerPage) });
            };
        }
    };

    handlePageClick = ({ selected }) => {
        let { itemsPerPage, blogs, startOffset } = this.state;
        startOffset = (selected * itemsPerPage) % blogs.length;
        this.setState({ startOffset, isSelected: selected });
    };

    render() {
        let { pageCount, blogsArray, category, isSelected, search } = this.state;

        return (
            <InnerLayout header='Main' fallback={<Loader />}>
                <Head
                    type="Website"
                    fallback={<Loader />}
                    path="/blogs/"
                    title="Blogs"
                    description="Here are the top preferred blockchain blogs that you would love to go through. All the blogs are developed to give you a crystal clear understanding of the preferences and essentiality that you might impose in the application of yours."
                />
                <main>
                    <div id="wrapper" className="softtik-page">
                        <div className="blog-page" >
                            <section className="banner-sec">
                                <div className="auto-container">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                            <div className="sec-title wow fadeIn" data-wow-delay='600ms'>
                                                <h2>Blogs</h2>
                                                <div className="form-row">
                                                    <div className="form-group col-lg-4 offset-lg-4 col-md-8 col-sm-12">
                                                        <input
                                                            id="search"
                                                            type="text"
                                                            value={search}
                                                            placeholder="Search"
                                                            onChange={(e) => this.handleChange(e.target.value)}
                                                        />
                                                        <button type="button" className="search-btn"><i className='icon fa fa-search'></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="category-banner-list">
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('All')} type="button" className={`category-link ${(category == 'All' || category == null || category == "null") && 'active'}`}>All</button>
                                                </li>
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('NFT')} type="button" className={`category-link ${category == 'NFT' && 'active'}`}>NFT</button>
                                                </li>
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('Metaverse')} type="button" className={`category-link ${category == 'Metaverse' && 'active'}`}>Metaverse</button>
                                                </li>
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('GameFi')} type="button" className={`category-link ${category == 'GameFi' && 'active'}`}>GameFi</button>
                                                </li>
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('DeFi')} type="button" className={`category-link ${category == 'DeFi' && 'active'}`}>DeFi</button>
                                                </li>
                                                <li>
                                                    <button rel="nofollow noopener" onClick={() => this.setCategory('Blockchain')} type="button" className={`category-link ${category == 'Blockchain' && 'active'}`}>Blockchain</button>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </section>

                            <div className="blog-page-slider-section position-relative">
                                <div className="blog-page-slider-section-content position-relative">
                                    {blogsArray.length > 0
                                        ? <div className="auto-container">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <div className="sec-title wow fadeIn" data-wow-delay='600ms'>
                                                        <h2>
                                                            {blogsArray.some(item => item.category == category) ? category : ''}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="slide">
                                                    <div className="carousel-item active">
                                                        {blogsArray && blogsArray.map((blog) => (
                                                            <div className="col-lg-4 col-md-6 col-sm-12 float-left" key={blog.slug}>
                                                                <Thumbnail blog={blog} category={category} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="blog-pagination row">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    onPageChange={(e) => this.handlePageClick(e)}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    breakClassName={"break-me"}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    forcePage={isSelected}
                                                    previousLabel="< prev"
                                                    renderOnZeroPageCount={null}
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        </div>
                                        : <h2 className="no-blog-found d-flex justify-content-center">No Blogs Found!!!</h2>
                                    }
                                </div>
                            </div>

                        </div>

                        {/* Contact */}
                        <section className='get-intouch-sec' id="get-intouch">
                        <Contact />
                        </section>
                    </div>
                </main>
            </InnerLayout>
        )
    }
}

export default allblogs;

function compare(a, b) {
    if (a.date > b.date)
        return -1;
    if (a.date < b.date)
        return 1;
    return 0;
}